@import 'src/styles/imports';

.component {
  display: flex;
  padding: 1em .6em;
  border: 1px solid $color-grey-1;
  border-radius: 8px;
  margin-top: 1.5em;
  background-color: var(--themes-colors-primary-light);
  align-items: center;
  flex-direction: row;

  .favourited-text {
    padding-right: .8em;
  }

  p {
    font-size: .8em;
    line-height: 1.2em;
    padding: 0 1em;
    font-family: $font-family-sans-serif;
  }

  a {
    text-decoration: underline;
  }

  button {
    font-size: .75em;
    padding: .8em;
  }

  .icon {
    width: 50px;

    path {
      fill: var(--themes-colors-primary);
    }
  }

}
