@import 'src/styles/imports';

.info {
  padding: 1.5em 1.5em 0;
}

.label,
.time-label {
  font-size: .625em;
  max-width: 70%;
  margin-bottom: .5em;
  color: $color-grey-9;
  white-space: nowrap;

  &.time-label {
    max-width: 100%;
    text-align: right;
  }

  @include desktop {
    font-size: .875em;
  }
}

.value {
  font-weight: $font-weight-bold;
  letter-spacing: .7px;

  @include desktop {
    font-size: 1.25em;
  }
}

.stat {
  display: grid;
  grid-template-columns: 2fr 1fr;

  span {
    width: fit-content;
    margin-right: 2em;
  }
}

.chart-title {
  font-size: 1.25em;
  margin-bottom: 1em;
  font-weight: $font-weight-bold;
}

.chart-description {
  font-size: .75em;
  margin-bottom: 1.5em;
}

.empty-space {
  height: 4em;
  margin-bottom: 2rem;
}
