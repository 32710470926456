@import 'src/styles/imports';

.remove-boundary {
  font-size: .875em;
  position: absolute;
  bottom: 6em;
  left: 50%;
  padding: .5em;
  border-radius: 50px;
  background-color: $color-black-65;
  color: $color-white;
  cursor: pointer;
  transform: translateX(-50%);

  @include desktop {
    bottom: 1em;
    padding: 1em;
  }
}

.map-loading {
  position: absolute;
  top: 1em;
  left: 50%;
  display: flex;
  height: 1em;
  padding: 1.25em;
  border-radius: $border-radius-medium;
  background-color: $color-black-40;
  color: $color-white;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-50%);

  @include desktop {
    padding: 1.25em 3em;
  }

  .map-spinner {
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.map {
  position: relative;
  height: calc(100vh - 80px);
  flex: 1 1 auto; // Map will grow and take all available space

  @include desktop {
    height: calc(100vh - $height-zoo-top-nav-offset);

    &.exp-height {
      height: calc(100vh - $height-exp-top-nav-offset);
    }
  }
}


.slide-up {
  display: block;

  @include not-mobile {
    display: none;
  }
}

.back-to-map-chip {
  position: fixed;
  z-index: calc($z-index-fixed-footer + 1);
  bottom: 10px;
  left: calc(50% - 41px);
  display: flex;
  width: 83px;
  max-width: 55em;
  height: 38px;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: $color-grey-6;
  color: $color-white;
  align-items: center;
  justify-content: space-between;

  svg path  {
    fill: $color-white;
    }
}

.listings-drawer {
  z-index: calc($z-index-fixed-footer - 1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-and-fadein {
  0% {
    opacity: 0;
    transform: scale(.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }

}

@-webkit-keyframes scale-and-fadein {
  0% {
    opacity: 0;
    -webkit-transform: scale(.2);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
