@import 'src/styles/imports';

.component {
  position: relative;
  border: 1px solid $color-grey-4;
  border-radius: 5px;
  margin: 0 .5em .5em 0;
}

.charts {
  display: flex;
  flex-direction: column;

  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &.modal {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: ($breakpoint-mobile)) and (max-width: 1150px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.chart-container {
  position: relative;
  padding: .3em;
}

.blurred-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
}

.title {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.primary {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  margin-right: .5em;
  background: var(--themes-colors-primary-link-color);
}

.legend-item {
  display: flex;
}

.info {
  padding: 1.5em 1.5em 0;
}

.label,
.time-label {
  font-size: .625em;
  margin-bottom: .5em;

  &.time-label {
    max-width: 100%;
    text-align: right;
  }

  @include desktop {
    font-size: .875em;
  }
}

.value {
  font-weight: $font-weight-bold;
  letter-spacing: .7px;

  @include desktop {
    font-size: 1.25em;
  }
}

.stat {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-summary {
  display: grid;
  padding: 0 1em;
  margin: auto;
  margin-bottom: 1em;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .bold {
    font-size: .75em;
    font-weight: $font-weight-bold;

    @include desktop {
      font-size: 1em;
    }
  }

  .location {
    font-size: .75em;
    overflow: hidden;
    width: 6em;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.empty {
  svg {
    width: 1.65em;
    height: 1.65em;
  }
}

.diff-column {
  border-left: 1px solid $color-grey-4;
}

.chart-title {
  font-size: 1.25em;
  margin-bottom: 1em;
  font-weight: $font-weight-bold;
}

.top-margin {
  margin-top: 1em;
}

.stats {
  padding: 1em 0;
  border-radius: 0 5px;
}

.login-cta {
  font-size: .875em;
  margin-top: 1em;

  span {
    color: var(--themes-colors-primary-link-color);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.feedback {
  font-size: .75em;
  display: flex;
  margin-top: 1em;
}

.cta {
  margin-left: .5em;
  color: var(--themes-colors-primary-link-color);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    color: var(--themes-colors-primary-focus-link-color);
    text-decoration: underline;
  }
}

.list-vs-sold {
  margin-bottom: 5rem;
}
