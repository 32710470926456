// sass-lint:disable no-important no-misspelled-properties
@import 'src/styles/imports';

.component {
  display: flex;
  padding: .8em 0;
  font-family: 'Manrope';
  flex-direction: column;
  justify-content: space-between;
  gap: 13px;

  .cta-button {
    width: 5.5em;
  }

  .overlay-button {
    font-size: 14px !important;
    width: 5.5em;
    padding: .7em 3.2em !important;
    border-width: 1.5px;
    border-radius: 32px !important;
    color: $color-dark-navy;
    font-weight: 700;

    &:active {
      background-color: $color-dark-navy-80 !important;
    }

    &:hover {
      background-color: $color-dark-navy;
      color: $color-white;
    }
  }

  .disclaimer {
    font-size: 16px;
    line-height: 20px;
    font-weight: $font-weight-thin;
  }
}

.component-small {
  .disclaimer {
    font-size: .9em;
    line-height: 1.2em;
  }

  .cta-button {
    margin-top: .5em;
  }
}
