// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: relative;
  overflow: hidden;
  box-shadow: $box-shadow-map-card;
  height: 130px;
  border: 1px solid $color-grey-1;
  border-radius: 16px;
  margin: 16px 8px;
  background: $color-white;
  color: var(--themes-colors-primary-dark);
  cursor: pointer;

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 40%;
    height: 100%;

    .image {
      transition: .3s ease;
    }

    &:hover {
      .image {
        transform: scale(1.07);
      }
    }
  }

  .right {
    position: absolute;
    right: 0;
    display: flex;
    width: 55%;
    height: 100%;
    padding-right: 8px;
    margin-left: 40%;
    flex-direction: column;
    justify-content: space-evenly;

    @include mobile {
      width: 57%;
    }
  }

  .price-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .blur-image {
    filter: blur(4px) !important; // Note: Blur on private listings
  }

  .street-address {
    font-size: .8em;

    p {
      color: $color-grey-18;
      font-weight: $font-weight-semibold;
    }
  }

  .street-address-link {
    font-size: .8em;

    p {
      color: $color-grey-18;
      font-weight: $font-weight-semibold;
    }

    :hover {
      text-decoration: underline;
    }
  }

  .text {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    color: $color-white;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.favourite {
  div {
    top: -.25em !important;
    width: 1.5em;
  }

  @include not-mobile {
    display: block;
  }
}

.provider-label {
  margin-top: 0;

  p {
    max-width: 85%;
    margin-top: 0;
  }

}

.provider-logo-container {
  position: absolute;
  right: 8px;
  bottom: 8px;
  overflow: hidden;
  width: 3em;
  height: 1.5em;

  .provider-logo {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}
