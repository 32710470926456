@import 'src/styles/imports';

.component {
  display: grid;
  padding: 0 1em;
  margin: auto;
  margin-bottom: 1em;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(4, 25%);

  .bold {
    font-size: .75em;
    font-weight: $font-weight-bold;

    @include desktop {
      font-size: 1em;
    }
  }

  .location {
    font-size: .75em;
    text-align: center;
  }
}

.label {
  font-size: .625em;
  max-width: 70%;
  margin-bottom: .5em;
  color: $color-black-50;
  white-space: nowrap;

  @include desktop {
    font-size: .625em;
    margin-right: .75em;
  }
}
