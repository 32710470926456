@import 'src/styles/imports';

.component {
  position: relative;
  z-index: $z-index-home-module-swiper;

  &.margin {
    margin-bottom: 1.5em;
  }

  &:hover .arrow {
    opacity: 1;
  }

  .slider {
    min-height: 18em;

    @media (max-width: 31.25em) {
      min-height: auto;
    }

    &.header {
      background-color: $color-grey-1;
    }
  }

  .carousel-item {
    width: 100%;
    height: 21em;
    cursor: pointer;

    @include desktop {
      width: 35em;
    }

    @include mobile {
      height: 18em;
    }

    &.full {
      width: 100%;
      height: 100%;

      .map {
        height: auto;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.no-image {
      width: 100% !important; // sass-lint:disable-line no-important
    }
  }

  .map {
    width: 100%;
    height: 25em;
    object-fit: cover;
  }

  .blur-image {
    pointer-events: none;
    filter: blur(8px) brightness(50%);
  }

  .sign-in-overlay {
    position: absolute;
    z-index: $z-index-sign-in-overlay;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    line-height: 1.5em;
    color: $color-white;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    @include not-mobile {
      font-size: 1.375em;
    }

    .cta {
      width: 50%;
      max-width: 20em;

      span {
        display: block;
        text-decoration: underline;
      }
    }
  }

  @include mobile-and-landscape {
    .arrow {
      display: none;
    }
  }
}
