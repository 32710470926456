@import 'src/styles/imports';

.row {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
}

.listing-detail {
  font-size: .75em;
  display: inline-flex;
  margin-right: 1em;
  align-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  .icon {
    margin-right: .3em !important; // sass-lint:disable-line no-important
  }
}
