@import 'src/styles/imports';

.component {
  overflow-y: hidden;
  color: $color-white;

  .mobile-bar {
    position: fixed;
    z-index: $z-index-bump;
    top: 0;
    right: 0;
    left: 0;
    display: none;
    padding: 1em;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: flex;
    }
    @include mobile-and-landscape {
      display: flex;
    }

    svg {
      width: 1em;
      height: 1em;
      cursor: pointer;

      path {
        fill: $color-white;
      }
    }
  }

  .image-carousel-container {
    height: calc(100vh - 8em);
    margin: 1.5em auto;

    > div {
      width: 100%;
      height: 100%;

      > div {
        width: 100%;
        height: 100%;

        > div {
          width: 100%;
          height: 100%;

          div {
            width: 100%;
            height: 100%;

            img {
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .rotate-disclaimer {
    position: fixed;
    z-index: $z-index-bump;
    right: 7%;
    bottom: 5%;
    left: 7%;
    display: flex;
    padding: 10px;
    border-radius: $border-radius-large;
    background-color: $color-white-10;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 20px;
      height: 20px;
    }

    p {
      font-size: 14px;
      width: calc(100% - 100px);
      line-height: 18px;
      margin-right: 20px;
      margin-left: 15px;
    }

    .button {
      border-radius: $border-radius-large;
    }
  }

  @include mobile-and-landscape {
    height: 100%;
    padding: 0;

    .image-carousel-container {
      max-width: 100%;
      height: 100%;
      margin: 0 auto;

      > div {
        height: 100%;
        margin-bottom: 0;

        > div {
          height: 100%;

          > div {
            height: 100%;
          }
        }
      }
    }
  }
}
