@import 'src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-bump;
  top: 1em;
  right: 1em;
  display: flex;
  justify-content: space-between;


  @include not-mobile {
    &.thumbnails {
      bottom: 7em;
    }
  }

  .custom-tooltip-position {
    left: 25%;
    border-radius: var(--themes-buttons-border-radius-large);
  }
}

.share {
  display: flex;
  min-width: 3em;
  min-height: 2em;
  border-radius: 100%;
  margin-right: .5em;
  background-color: $color-black-65;
  align-items: center;
  justify-content: center;

  @include not-mobile {
    min-width: 3em;
    min-height: 3em;
  }

  label {
    cursor: pointer;
    align-items: center;
    justify-content: center;

    svg {
      width: 1.5em;
      height: 1.5em;

      path {
        fill: $color-white;
      }
    }

    &:hover {
      svg path {
        fill: $color-white;
      }
    }
  }
}

.exp {
  > div {
    box-shadow: var(--themes-box-shadow);
    background-color: $color-white !important; // sass-lint:disable-line no-important

    &.share {
      svg {
        path {
          fill: var(--themes-colors-primary-font-color) !important; // sass-lint:disable-line no-important
        }
      }
    }
  }
}
