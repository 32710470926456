@import 'src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-bump;
  top: calc(50% - 1.5em);
  display: flex;
  box-shadow: $color-arrow-box-shadow;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background-color: $color-white;
  cursor: pointer;
  opacity: 1;
  align-items: center;
  justify-content: center;
  transition: all $transition-duration ease;

  &:hover {
    background-color: var(--themes-colors-carousel-arrow-hover);
  }

  &:active {
    background-color: var(--themes-colors-carousel-arrow-focus);
  }

  @include not-mobile {
    opacity: 0;

    &.thumbnails {
      top: calc(50% - 4.5em);
    }
  }

  &.hide {
    display: none;
  }

  &.card {
    top: calc(50% - 6.2em);
  }

  &.prev {
    left: 1em;

    svg {
      padding-left: .3em;
      transform: rotate(180deg);
    }
  }

  &.next {
    right: 1em;
  }

  svg {
    width: 1.3em;
    height: 1.3em;
  }
}
