@import 'src/styles/imports';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listing-detail {
  font-size: 12px;
  display: inline-flex;
  margin-right: 1em;
  margin-bottom: .25em;
  align-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  .icon {
    margin-right: .3em;
  }

  span {
    display: inline-block;
    margin-left: .15em;
    color: $color-listing-detail-label;
    font-weight: 300;
  }
}

.sign-in {
  font-size: .875em;
  margin-top: .4em;
}

.arrow-up {
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 10px solid $color-green;
  border-left: 5px solid transparent;
  margin-left: .25em;
}

.arrow-down {
  width: 0;
  height: 0;
  border-top: 10px solid $color-red;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-bottom: -.15em;
  margin-left: .25em;
}
