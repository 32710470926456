@import 'src/styles/imports';

.component {
  font-size: .6875em;
  z-index: $z-index-panel;
  box-shadow: $box-shadow;
  border-radius: 6.5em;
  color: $color-white;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &.size-1 {
    @include pin-of-size(18, false);
  }

  &.size-2 {
    @include pin-of-size(22, false)
  }

  &.size-3 {
    @include pin-of-size(26, false)
  }

  .nib {
    position: absolute;
    bottom: -7px;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    transform: translateX(50%);
  }

  &.price-pin {
    position: relative;
    display: inline-flex;
    width: auto;
    height: auto;
    padding: .5em;
    border-radius: 0;
    white-space: nowrap;

  }
}

.visited {
  background-color: $color-visited-map-pin !important; // sass-lint:disable-line no-important
  filter: drop-shadow(0 1px 4px $color-black-20) !important; // sass-lint:disable-line no-important

  .nib {
    border-top: 7px solid $color-visited-map-pin !important; // sass-lint:disable-line no-important
  }
}

.available {
  background-color: var(--themes-colors-map-pin-for-sale);
  filter: drop-shadow(0 0 1px var(--themes-colors-map-pin-for-sale));

  .nib {
    border-top: 7px solid var(--themes-colors-map-pin-for-sale);
  }

  &:hover {
    opacity: .8;
  }

  &:focus {
    background-color: var(--themes-colors-map-pin-for-sale-focus);
    filter: drop-shadow(0 2px 4px rgba(49, 115, 155, 0.25));
  }
}

.unavailable {
  background-color: var(--themes-colors-primary-sold);
  filter: drop-shadow(0 0 1px var(--themes-colors-primary-sold));

  .nib {
    border-top: 7px solid var(--themes-colors-primary-sold);
  }

  &:hover {
    opacity: .8;
  }

  &:focus {
    background-color: var(--themes-colors-primary-sold-focus);
    filter: drop-shadow(0 2px 4px rgba(49, 115, 155, 0.25));
  }
}

.selected {
  background-color: var(--themes-colors-primary-dark);

  .nib {
    border-top: 7px solid var(--themes-colors-primary-dark);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fadeIn .5s ease-in-out forwards;
}
