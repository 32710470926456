@import 'src/styles/imports';

$bar-height: 4em;

.component {
  width: 100%;
  max-width: 65em;
  height: 100%;
  padding-top: $bar-height;
  padding-bottom: 2em;

  .bar {
    position: fixed;
    z-index: $z-index-header;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    max-width: 65em;
    height: $bar-height;
    padding: 1em;
    background-color: var(--themes-colors-primary-background);
    color: var(--themes-colors-primary-font-color);
    align-items: center;
    justify-content: space-between;

    .bar-right {
      display: flex;
      align-items: center;
    }

    svg {
      height: 1em;
      cursor: pointer;

      path {
        fill: var(--themes-colors-primary-font-color);
      }

    }

    .expand {
      border-bottom: 0;
    }

    @include not-mobile {
      left: auto;
      padding: 1.5em 3em;
    }
  }

  .listing-content {
    @include layout-container;
    display: flex;
    flex-direction: row;

    @include desktop {
      display: flex;
      padding: 0 2.5em;
      justify-content: space-between;
    }
  }

  .left-section {
    width: 100%;
    padding-bottom: 2em;

    .map {
      height: 25em;
    }

    @include desktop {
      width: calc(100% - 20em);
      padding-right: 2em;
    }
  }

  .request-info-section {
    top: 0;
    right: 0;
    display: block;
    width: 20em;
    min-height: 100%;
    margin-top: -5em;


    section {
      position: sticky;
      top: 5em;
    }
  }

  .request-info-section-lowered {
    margin-top: 0;
  }

  .zoopraisal-container {
    margin-bottom: 1em;
  }

  .smdc-tabs {
    margin-top: 2em;
  }

  &.exp-theme {
    .bar {
      .expand {
        margin: 0 1em;
      }
    }
  }
}

.modal-header-icon {
  max-width: 150px;
}