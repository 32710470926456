@import '/src/styles/imports';

.callout-root {
  position: absolute;
  box-shadow: $box-shadow-map-callout;
  width: 25em;
  padding: 4px 8px;
  border-radius: 16px;
  background: $color-white;

  .callout-header {
    position: relative;
    display: flex;
    padding-top: 12px;
    padding-bottom: 8px;
    align-items: center;
    justify-content: center;

    p {
      font-size: 18px;
      line-height: 135%;
      color: var(--themes-colors-primary-dark);
      font-style: normal;
      font-weight: 700;
      text-align: center;
      letter-spacing: .25px;
    }

    span {
      font-size: 18px;
      line-height: 135%;
      font-weight: $font-weight-regular;
    }
  }

  .callout-body {
    overflow-y: auto;
    -ms-overflow-style: none;  // Hiding scrollbar IE and Edge
    scrollbar-width: none;  // sass-lint:disable-line no-misspelled-properties
  }

  .wrapper {
    position: absolute;
    top: 4px;
    right: 1.5em;
    display: inline-block; 

    &.zoocasa-theme{
      &:hover {
        &::before {
          content: '';
          position: absolute;
          z-index: 0;
          top: 50%;
          left: 50%;
          width: 2.5em;
          height: 2.5em;
          border-radius: 50%;
          background-color: $color-warm-black-10;
          transform: translate(-50%, -50%);
        }
      }
    }

    .close {
      position: relative;
      z-index: 1;
      display: flex;
      width: 1em;
      height: 1em;
      padding: 0.5em;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
  }
}

@-webkit-keyframes scale-and-fadein {
  0% {
    opacity: 0;
    -webkit-transform: scale(.2);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@keyframes scale-and-fadein {
  0% {
    opacity: 0;
    transform: scale(.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
