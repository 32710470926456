@import 'src/styles/imports';

.callout-root {
  position: relative;
  z-index: calc($z-index-fixed-footer + 1);
  overflow: hidden;
  width: 100%;
  max-height: calc(85vh - $height-top-nav-mobile); // sass-lint:disable-line no-important
  padding: 4px 8px;
  background: $color-white;

  .drawer-top-line {
    max-width: 10%;
    border-top: 3px solid $color-grayish-blue;
    border-radius: 15%;
    margin: auto;
  }

  .callout-header {
    position: relative;
    display: flex;
    height: 3em;
    padding-top: 10px;
    padding-bottom: 8px;
    align-items: center;
    justify-content: center;

    p {
      font-size: 16px;
      line-height: 155%;
      color: var(--themes-colors-primary-dark);
      font-style: normal;
      font-weight: 700;
      text-align: center;
      letter-spacing: .25px;
    }

    span {
      font-size: 16px;
      line-height: 155%;
      font-weight: $font-weight-regular;
    }

    .callout-header-left {
      flex: 1 1 0;
    }

    .callout-header-middle {
      flex: 2 1 0;
    }

    .callout-header-right {
      display: flex;
      padding-right: .5em;
      align-items: center;
      justify-content: flex-end;
      flex: 1 1 0;
    }
  }

  .callout-body {
    overflow-y: auto;
    max-height: calc(85vh - (135px)) !important; // sass-lint:disable-line no-important
  }

  .close {
    width: 1em;
    height: 1em;
    margin-right: 1em;
    cursor: pointer;
  }
}

@-webkit-keyframes scale-and-fadein {
  0% {
    opacity: 0;
    -webkit-transform: scale(.2);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@keyframes scale-and-fadein {
  0% {
    opacity: 0;
    transform: scale(.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
